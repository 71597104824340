var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('back-button',{attrs:{"color":_vm.companyData.companyColor},on:{"back":function($event){return _vm.$router.push({name:'LeasingDashboard'})}}}),_c('div',{staticClass:" display-center tw-w-full"},[_c('div',{staticClass:" main-body  tw-mt-20"},[_c('p',{staticClass:"head-txt",style:({color : _vm.companyData.companyColor})},[_vm._v("Edit Hiring Criteria")]),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-between tw-mb-4"},[_c('validation-provider',{staticClass:"route",attrs:{"name":"route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"header"},[_vm._v("Route")]),_c('v-select',{staticClass:"mt-4 block",attrs:{"items":_vm.routes,"item-text":_vm.getRouteText,"item-value":"id","hide-details":"","placeholder":"Lagos-Benin","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"arrow-down"}})]},proxy:true}],null,true),model:{value:(_vm.hiringCriteriaData.selectedRoutes),callback:function ($$v) {_vm.$set(_vm.hiringCriteriaData, "selectedRoutes", $$v)},expression:"hiringCriteriaData.selectedRoutes"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('validation-provider',{attrs:{"name":"vehicle type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"header"},[_vm._v("Vehicle Type")]),_c('v-select',{staticClass:"mt-4 block",attrs:{"items":_vm.vehicleTypeAndCapacities,"hide-details":"","item-text":"fullDetail","placeholder":"Vehicle type","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"arrow-down"}})]},proxy:true}],null,true),model:{value:(_vm.hiringCriteriaData.selectedVehicleType),callback:function ($$v) {_vm.$set(_vm.hiringCriteriaData, "selectedVehicleType", $$v)},expression:"hiringCriteriaData.selectedVehicleType"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-4 "},[_c('label',{staticClass:"header"},[_vm._v("Price/day")]),_c('div',{staticClass:"tw-mt-6"},[_c('validation-provider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"hide-details":"","item-text":"name","placeholder":"","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('icons',{attrs:{"name":"naira-sign","color":_vm.companyData.companyColor}})]},proxy:true}],null,true),model:{value:(_vm.hiringCriteriaData.hirePrice),callback:function ($$v) {_vm.$set(_vm.hiringCriteriaData, "hirePrice", $$v)},expression:"hiringCriteriaData.hirePrice"}}),_c('span',{staticClass:"error-text"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"tw-pt-0 tw-mt-10"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"hide-details":"","color":_vm.companyData.companyColor},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"header tw-mt-1"},[_vm._v(" Discount ")])]},proxy:true}],null,true),model:{value:(_vm.hiringCriteriaData.discount),callback:function ($$v) {_vm.$set(_vm.hiringCriteriaData, "discount", $$v)},expression:"hiringCriteriaData.discount"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"tw-flex tw-mt-5"},[_c('validation-provider',{attrs:{"name":"discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.hiringCriteriaData.discount),expression:"hiringCriteriaData.discount"}],class:{ 'header': _vm.hiringCriteriaData.discount, 'header-disabled': !_vm.hiringCriteriaData.discount }},[_vm._v("No of Days")]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.hiringCriteriaData.discount),expression:"hiringCriteriaData.discount"}],staticClass:"mt-4 mr-3",attrs:{"hide-details":"","item-text":"name","placeholder":"6","solo":""},model:{value:(_vm.hiringCriteriaData.numberOfDiscountedDays),callback:function ($$v) {_vm.$set(_vm.hiringCriteriaData, "numberOfDiscountedDays", $$v)},expression:"hiringCriteriaData.numberOfDiscountedDays"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.hiringCriteriaData.discount),expression:"hiringCriteriaData.discount"}],class:{ 'header': _vm.hiringCriteriaData.discount, 'header-disabled': !_vm.hiringCriteriaData.discount }},[_vm._v("Discount Value")]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.hiringCriteriaData.discount),expression:"hiringCriteriaData.discount"}],staticClass:"mt-4",attrs:{"hide-details":"","item-text":"name","placeholder":"10%","solo":""},model:{value:(_vm.hiringCriteriaData.discountValue),callback:function ($$v) {_vm.$set(_vm.hiringCriteriaData, "discountValue", $$v)},expression:"hiringCriteriaData.discountValue"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"tw-mt-10"},[_c('new-base-button',{attrs:{"buttonText":'Cancel',"isOutlined":true,"outlined":"outlined","color":_vm.companyData.companyColor}}),_c('new-base-button',{staticClass:"tw-ml-5",attrs:{"buttonText":'Finish',"color":_vm.companyData.companyColor,"loading":_vm.loading},on:{"click":_vm.updateHiringCriteria}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }