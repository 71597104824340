<script>
import {
  getAllHireRouteOfTSp,
  getAllVehicleType,
  updateCriteria,
} from "@/services/api/APIService";
import BackButton from "@/components/reuseables/BackButton.vue";
import Icons from "@/components/reuseables/Icons.vue";
import NewBaseButton from "@/components/reuseables/NewBaseButton.vue";
import {mapGetters} from "vuex";


export default {
  name: 'EditHiringCriteria',

  components: {NewBaseButton, Icons, BackButton},
  data(){
    return{
      loading: false,
      routes:[],
      vehicleTypeAndCapacities: [],
      hireData:{
        discountValue: "",
        numberOfDiscountedDays: "",
        hirePrice: '',
        discount: false,
        selectedRoutes: {},
        selectedVehicleType:
            {
              vehicleId : null,
              vehicleType : "",
              vehicleCapacity: ""
            },
      },
      companyData: {},
      hiringCriteriaData: {},
      refresh: true
    }
  },
  computed:{
    ...mapGetters("leasingComponent",["hiringCriteriaData", "selectedCriteria"])
  },
  methods:{

    async getAllRoute() {
      let data = {}
      data.transportCompanyId = this.companyData.id
      await getAllHireRouteOfTSp(data)
          .then((res) => {
            this.routes = res.data
            this.routes.sort(function (a, b) {
              return (a.departure.city.localeCompare(b.destination.city))
            })
            this.routes.sort(function (a, b) {
              return (a.departureTerminalName.localeCompare(b.departureTerminalName))
            })
          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            })
          });
    },

    async getVehicleType() {
      let data = {}
      data.transportCompanyId = this.companyData.id;
      await getAllVehicleType(data)
          .then((res) => {
            res.data.forEach(vehicle => {
              let vehicleObject = {};
              vehicleObject.vehicleType = vehicle.vehicleType;
              vehicleObject.vehicleCapacity = vehicle.vehicleCapacity;
              vehicleObject.vehicle = vehicle;
              vehicleObject.fullDetail = vehicle.vehicleType + " - " + vehicle.vehicleCapacity;
              this.vehicleTypeAndCapacities.push(vehicleObject);

            })
          })
          .catch((err) => {
            console.log(err.response)
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            })
          })
    },

    getRouteText(item){
      return this.toSentenceCase(item.departure.city) +" "+
          this.toSentenceCase(item.departure.state) + " "+
          this.toSentenceCase(item.departure.country) + " - "+
          this.toSentenceCase(item.destination.city) + " "+
          this.toSentenceCase(item.destination.state) + " "+
          this.toSentenceCase(item.destination.country)
    },

    toSentenceCase(text){
      if (text) {
        text = text.toLowerCase()
        return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
      }
    },


    async updateHiringCriteria(){
      let data = {}
      data = this.hiringCriteriaData
      data.id = this.$store.getters["leasingComponent/selectedCriteria"].id

      await updateCriteria(data).then(()=>{
      this.loading = false
      this.$displaySnackbar({
        message: "Criteria successfully updated",
        success: true
      })
      }).catch(err =>{
        this.$displaySnackbar({
        message: err.response.data.details[0],
        success: false
        })
        this.loading = false
        console.log(err.response.data.details[0])
      })

      this.$router.push({name: "AllHiringCriteria"})
      window.location.reload()
    },
  },
  async created() {
    console.log(this.selectedCriteria)
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    await this.getVehicleType();
    await this.getAllRoute();
  }
}

</script>

<template>
  <div>
    <back-button :color="companyData.companyColor" @back="$router.push({name:'LeasingDashboard'})" />

    <div class=" display-center tw-w-full">
      <div class=" main-body  tw-mt-20">
        <p class="head-txt" :style="{color : companyData.companyColor}">Edit Hiring Criteria</p>
        <div class="tw-flex tw-w-full tw-justify-between tw-mb-4">
          <validation-provider name="route" rules="required" v-slot="{ classes, errors }" class="route">
            <label class="header">Route</label>
            <v-select class="mt-4 block" :items="routes" v-model="hiringCriteriaData.selectedRoutes" :item-text="getRouteText" item-value="id"
                      hide-details
                      placeholder="Lagos-Benin" solo>
              <template #append>
                <icons name="arrow-down" />
              </template>
            </v-select>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

        </div>

        <div class="mb-4">
          <validation-provider name="vehicle type" rules="required" v-slot="{ classes, errors }">
            <label class="header">Vehicle Type</label>
            <v-select class="mt-4 block" v-model="hiringCriteriaData.selectedVehicleType"
                      :items="vehicleTypeAndCapacities" hide-details item-text="fullDetail"
                      placeholder="Vehicle type" solo>
              <template #append>
                <icons name="arrow-down" />
              </template>
            </v-select>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>

        <div class="mb-4 ">
          <label class="header">Price/day</label>
          <div class="tw-mt-6">
            <validation-provider name="price" rules="required" v-slot="{ classes, errors }">
              <v-text-field  class="mt-4" v-model="hiringCriteriaData.hirePrice" hide-details item-text="name"
                             placeholder="" solo>
                <template v-slot:prepend-inner>
                  <icons name="naira-sign" :color="companyData.companyColor" />
                </template>
              </v-text-field>
              <span class="error-text"> {{errors[0]}} </span>
            </validation-provider>
          </div>

          <div class="tw-pt-0 tw-mt-10">
            <validation-provider v-slot="{classes, errors}">
              <v-checkbox hide-details :color="companyData.companyColor" v-model="hiringCriteriaData.discount">
                <template #label>
                  <label class="header tw-mt-1">
                    Discount
                  </label>
                </template>
              </v-checkbox>
              <span class="error-text">{{errors[0]}}</span>
            </validation-provider>
          </div>

          <div class="tw-flex tw-mt-5">
            <validation-provider name="discount" v-slot="{ classes, errors }">
              <label :class="{ 'header': hiringCriteriaData.discount, 'header-disabled': !hiringCriteriaData.discount }"
                     v-show="hiringCriteriaData.discount">No of Days</label>
              <v-text-field class="mt-4 mr-3" hide-details item-text="name"
                            placeholder="6" solo v-show="hiringCriteriaData.discount" v-model="hiringCriteriaData.numberOfDiscountedDays">
              </v-text-field>
              <span class="error-text">{{errors[0]}}</span>
            </validation-provider>

            <validation-provider name="duration"  v-slot="{ classes, errors }">
              <label :class="{ 'header': hiringCriteriaData.discount, 'header-disabled': !hiringCriteriaData.discount }"
                     v-show="hiringCriteriaData.discount">Discount Value</label>
              <v-text-field class="mt-4" hide-details item-text="name" placeholder="10%" solo v-show="hiringCriteriaData.discount"
                            v-model="hiringCriteriaData.discountValue">
              </v-text-field>
              <span class="error-text">{{errors[0]}}</span>
            </validation-provider>
          </div>


          <div class="tw-mt-10">
          <new-base-button :buttonText="'Cancel'" :isOutlined="true" outlined="outlined" :color="companyData.companyColor"/>
          <new-base-button :buttonText="'Finish'" class="tw-ml-5" @click="updateHiringCriteria" :color="companyData.companyColor" :loading="loading"/>
        </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.head-container{
  min-width: 100%;
}

.route{
  width: 100%;
}
.add-route{
  width: 5%;
}

.main-body{
  width: 1100px;
  height: fit-content;
  border-radius: 10px;
  background: #FDFFFC;
  padding: 50px;
  margin-left: 130px;
  @media screen and (max-width: 768px){
    margin-left: 120px;
  }
  @media screen and (max-width: 375px){
    margin-left: -25px;
  }
}
.head-txt{
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.header{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}
.block{
  @media screen and (max-width: 375px){
    width: 320px;
  }
}
.txt-field{
  width: 290px;

  @media screen and (max-width: 375px){
    width: 220px;
  }
}
.display-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
span.error-text {
  color: #EB5757;
  font-size: 12px;
  margin-top: 5px;
  font-family: inter;
  font-weight: 500;
}
</style>